import { Image } from "@fluentui/react-northstar";
import { QRCodeSVG } from "qrcode.react";
import { AreaWidgetSizeConstraints } from "../enums/AreaWidgetSizeConstraints";
import {
  ExamplePlaceholderData,
  fillPlaceholders,
} from "../services/CardTextPlaceholderService";
import { AvatarAreaWidgetSettings } from "./AvatarAreaWidgetSettings";
import { TextAreaWidgetSettings } from "./TextAreaWidgetSettings";

type WidgetType = "qr" | "avatar" | "text";

export class AreaWidgetData {
  x: number = 0;
  y: number = 0;
  width: number = 25;
  height: number = 25;
  sizeConstraint: AreaWidgetSizeConstraints;
  content: React.ReactNode = null;
  type: WidgetType;
  data: Map<string, any> = new Map();

  constructor(type: WidgetType, sizeConstraint: AreaWidgetSizeConstraints) {
    this.type = type;
    this.sizeConstraint = sizeConstraint;
  }
}

export class TextAreaWidgetData extends AreaWidgetData {
  settings: TextAreaWidgetSettings;

  constructor(settings?: TextAreaWidgetSettings, containerHeight?: number) {
    super("text", AreaWidgetSizeConstraints.None);

    this.settings = settings ?? {
      centered: false,
      color: "#ffffff",
      size: 4,
      text: "",
      weight: 400,
    };

    this.content = (
      <span
        ref={(spanRef) => {
          if (!spanRef) {
            return;
          }

          this.data.set("spanRef", spanRef);

          spanRef.textContent = fillPlaceholders(
            this.settings.text,
            ExamplePlaceholderData
          );
        }}
        className="inline-block h-full w-full select-none overflow-hidden font-rubik leading-none"
        style={{
          color: this.settings.color,
          fontSize: `${(this.settings.size / 100) * (containerHeight ?? 0)}px`,
          textAlign: this.settings.centered ? "center" : "initial",
          fontWeight: this.settings.weight,
        }}
      >
        {fillPlaceholders(this.settings.text, ExamplePlaceholderData)}
      </span>
    );
  }
}

export class QrCodeAreaWidgetData extends AreaWidgetData {
  constructor() {
    super("qr", AreaWidgetSizeConstraints.Square);

    this.content = (
      <QRCodeSVG
        value={fillPlaceholders("{VCard}", ExamplePlaceholderData)}
        size={512}
        className="!h-full !w-full"
      ></QRCodeSVG>
    );
  }
}

export class AvatarAreaWidgetData extends AreaWidgetData {
  settings: AvatarAreaWidgetSettings;

  constructor(settings?: AvatarAreaWidgetSettings) {
    super("avatar", AreaWidgetSizeConstraints.Square);

    this.settings = settings ?? {
      circular: true,
    };

    this.content = (
      <Image
        className="select-none"
        ref={(img) => {
          this.data.set("imgRef", img);
        }}
        draggable={false}
        src={fillPlaceholders("{PhotoUrl}", ExamplePlaceholderData)}
        circular={this.settings.circular}
      />
    );
  }
}

export const isTextAreaWidgetData = (
  widgetData: AreaWidgetData
): widgetData is TextAreaWidgetData => {
  return widgetData.type === "text";
};

export const isAvatarAreaWidgetData = (
  widgetData: AreaWidgetData
): widgetData is AvatarAreaWidgetData => {
  return widgetData.type === "avatar";
};

export const isQrCodeAreaWidgetData = (
  widgetData: AreaWidgetData
): widgetData is QrCodeAreaWidgetData => {
  return widgetData.type === "qr";
};
