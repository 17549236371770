import { Client } from "@microsoft/microsoft-graph-client";
import { UserProfile } from "../models/UserProfile";

export const getUserProfileAndPicture = async (
  graph: Client,
  setUserProfile: (arg0: UserProfile) => void,
  setProfilePictureDimensions: (arg0: number[]) => void,
  setPhotoUrl: (arg0: string) => void
) => {
  await graph
    .api("/me")
    .select(
      "businessPhones,displayName,givenName,jobTitle,mail,mobilePhone,surname,companyName,streetAddress,city,postalCode,country"
    )
    .get()
    .then(async (userProfile: UserProfile) => {
      const replaceHyphenWithNonBreaking = (text: string) => {
        return text.replaceAll("-", "\u2011");
      };

      userProfile.displayName = replaceHyphenWithNonBreaking(
        userProfile.displayName
      );
      userProfile.givenName = replaceHyphenWithNonBreaking(
        userProfile.givenName
      );
      userProfile.surname = replaceHyphenWithNonBreaking(userProfile.surname);

      /*
        Note from Microsoft Docs https://learn.microsoft.com/en-us/graph/api/resources/user?view=graph-rest-1.0
        Although this is a string collection, only one number can be set for this property.
      */
      const businessPhones =
        userProfile.businessPhones as unknown as Array<string>;
      userProfile.businessPhones = businessPhones.at(0) ?? null;

      // overwrite specific attributes that are editable by the user
      [
        "mobilePhone",
        "businessPhones",
        "jobTitle",
        "companyName",
        "streetAddress",
        "city",
        "postalCode",
        "country",
      ].forEach((propertyName) => {
        const storageProp = window.localStorage.getItem(propertyName);
        if (!storageProp) {
          return;
        }

        userProfile[propertyName as keyof UserProfile] = storageProp;
      });

      setUserProfile(userProfile);

      if (window.localStorage.getItem("profilePicture")) {
        const profilePicture = JSON.parse(
          window.localStorage.getItem("profilePicture")!
        );

        setProfilePictureDimensions([
          profilePicture.width,
          profilePicture.height,
        ]);

        setPhotoUrl(profilePicture.src);

        return Promise.resolve();
      }

      try {
        const metadata = await graph.api("/me/photo").get();
        setProfilePictureDimensions([metadata.width, metadata.height]);
        const photo = await graph.api("/me/photo/$value").get();
        if (photo) {
          // Bild laden und zu base64 umwandeln, da der HTML-To-Image Service das blob nicht verarbeiten kann
          const reader = new FileReader();

          reader.onload = () => {
            setPhotoUrl(reader.result as string);
          };

          reader.onerror = () => {
            setPhotoUrl("generic_pfp.png");
          };

          reader.readAsDataURL(photo);
        } else {
          setPhotoUrl("generic_pfp.png");
        }
      } catch (error) {
        console.error(error);
        setPhotoUrl("generic_pfp.png");
      }
    })
};
